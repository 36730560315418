// If the document is ready already, fire the callback immediately, otherwise wait for DOMContentLoaded
export function domReady(callback) {
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
  ) {
    callback()
  } else {
    document.addEventListener('DOMContentLoaded', callback)
  }
}

