import { trackEvent } from '@/analytics/track'
import { unescape } from 'lodash'

window.Agra = window.Agra || {}
window.Agra.trackEvent = trackEvent

window.Agra.alertMessageSuccess = function (message) {
  const clientSideAlerts = document.querySelector('.client-side-alerts')
  if (clientSideAlerts) {
    clientSideAlerts.querySelector('.alert').style.display = 'none'
    clientSideAlerts.querySelector('.alert.alert-success > span').innerText = unescape(message)
    clientSideAlerts.querySelector('.alert.alert-success').style.display = 'block'
  }
}

window.Agra.alertMessageDanger = function (message) {
  const clientSideAlerts = document.querySelector('.client-side-alerts')
  if (clientSideAlerts) {
    clientSideAlerts.querySelector('.alert').style.display = 'none'
    clientSideAlerts.querySelector('.alert.alert-danger > span').innerText = unescape(message)
    clientSideAlerts.querySelector('.alert.alert-danger').style.display = 'block'
  }
}
