import { objectKeysToCamelCase } from './transform-object-keys'
import { urlWithParams } from './url-params'

const STATUS_ERROR = 'error'
const GET_VERB = 'GET'
const POST_VERB = 'POST'
const PATCH_VERB = 'PATCH'
const PUT_VERB = 'PUT'
const DELETE_VERB = 'DELETE'
const PARAMS_IN_BODY_METHODS = [POST_VERB, PATCH_VERB, PUT_VERB, DELETE_VERB]

function get(options) {
  return ajaxBase({...options, method: GET_VERB})
}

function post(options) {
  return ajaxBase({...options, method: POST_VERB})
}

function patch(options) {
  return ajaxBase({...options, method: PATCH_VERB})
}

function put(options) {
  return ajaxBase({...options, method: PUT_VERB})
}

const errorTypes = {
  FETCH_FAILED: 'fetchFailed',
  NOT_OK_HTTP_STATUS: 'nonOkHttpStatus'
}

async function ajaxBase({url, params, method, headers, data: requestData, acceptContentType='application/json',
                         successHandler, errorHandler = null, throwOnFailure = false, camelCaseResponse = true}) {
  // If we're doing a GET, encode the params in the URL. Otherwise, we'll encode into the request body
  const parameterizedUrl = (method === GET_VERB) ? urlWithParams(url, params || {}) : url

  // By default, do not set the `body` parameter. For GET and other methods that do not accept a body,
  // even setting `body: null` can cause errors in some browsers.
  const maybeBody = {}

  // by default, communicate with the server via json
  let contentType = 'application/json;charset=UTF-8'

  if (PARAMS_IN_BODY_METHODS.includes(method)) {
    if (requestData === undefined) {
      // We need to do send as URL encoded data, since we accept arbitrary params.
      maybeBody.body = new URLSearchParams(params).toString()
      contentType = 'application/x-www-form-urlencoded'
    } else {
      // Need to JSON.stringify the bodyData here or it will be serialized
      // as [object Object] by default
      maybeBody.body = JSON.stringify(requestData)
    }
  }

  let response = null
  try {
    response = await fetch(parameterizedUrl, {
      method,
      headers: {
        ...headers,
        ...maybeCsrfToken(),
        'Accept': acceptContentType,
        'Content-Type': contentType,
        credentials: 'same-origin'
      },
      ...maybeBody
    })
  } catch (e) {
    if (throwOnFailure) {
      throw e
    } else {
      document.dispatchEvent(new CustomEvent('agra:ajax-error', {detail: {errorType: errorTypes.FETCH_FAILED}}))
      return
    }
  }

  // Check that HTTP status is OK
  if (!response.ok) {
    let errorBody
    try {
      errorBody = await response.json()
    } catch {
      // couldn't parse JSON
      errorBody = {error: `Response status was ${response.status}`}
    }

    if (throwOnFailure) {
      throw errorBody
    } else {
      document.dispatchEvent(new CustomEvent('agra:ajax-error', {
        detail: {
          errorType: errorTypes.NOT_OK_HTTP_STATUS,
          httpStatusCode: response.status,
          message: errorBody.message
        }
      }))
      return
    }
  }

  // Parse the response body
  if (response.status === 204) {
    // No response body to parse. Call the provided success handler function and we're done.
    if (successHandler) {
      successHandler(null)
    }
    return
  }

  // Parse body as JSON if that's the expected response
  const jsonRegexContentType = /json/i
  if (jsonRegexContentType.test(acceptContentType)) {
    const jsonResponse = await response.json()

    const transformedResponse = camelCaseResponse ? objectKeysToCamelCase(jsonResponse) : jsonResponse

    if (Array.isArray(jsonResponse)) {
      // Send the array data to the provided success handler function
      if (successHandler) {
        successHandler(transformedResponse)
      }
      return
    }

    // If we get here, we can assume that the JSON data is a hash. Extract common fields from it.
    const { status, message, data, ...rest } = transformedResponse

    // If the response has "status": "error", that's a 'business logic' error that the caller should handle.
    // Send it to the provided error handler function. If we were not provided an error handler function,
    // throw an exception.
    if (status === STATUS_ERROR) {
      if (errorHandler) {
        errorHandler({ message, info: rest })
        return
      } else {
        let error = new Error(message)
        error.info = rest
        throw error
      }
    }

    // Send the body, minus the 'status', to the provided success handler function
    if (successHandler) {
      successHandler({ message, data, ...rest })
    }
  } else {
    // Send the body as unparsed text to the provided success handler function
    const bodyText = await response.text()
    if (successHandler) {
      successHandler(bodyText)
    }
  }
}

function maybeCsrfToken() {
  const tokenElement = document.querySelector('meta[name="csrf-token"]')
  if (tokenElement) {
    return {
      'X-CSRF-Token': tokenElement.getAttribute('content')
    }
  }
  else {
    return {}
  }
}

export default {
  get,
  post,
  // We inline the delete method here because `delete` is a JS keyword
  delete: options => {
    return ajaxBase({...options, method: DELETE_VERB})
  },
  put,
  patch,

  errorTypes
}
