import isEmpty from 'lodash/isEmpty'

export function trackShareOpen(socialKind, entityType, entitySlug) {
  const trackingEventName = `Opened ${socialKind} Share for ${entityType}`
  let trackingParams = {
    category: 'recommendation',
    action: socialKind,
    name: `${entityType} ${entitySlug}`
  }

  if (entitySlug) {
    trackingParams[entityType.toLowerCase()] = entitySlug
  }

  trackEvent(trackingEventName, trackingParams)
}

function trackEventWithGoogleAnalytics(event, parameters, identity, windowGlobal) {
  if (windowGlobal.gtag !== undefined) {
    // this is using nifty object spread syntax to map things onto new field names
    // const { key:renamedKey, ...restOfTheKeys } = anObject
    const { action: eventName, ...params } = parameters
    const eventNameWithFallback = eventName || event
    windowGlobal.gtag('event', eventNameWithFallback, paramsForGoogleAnalytics(params))
  }
}

function trackEventWithGtm(event, parameters, identity, windowGlobal) {
  if (windowGlobal.dataLayer !== undefined) {
    const dataLayerEvent = Object.assign(parameters, {event: event})
    if (identity['email'] && windowGlobal.sendEnhancedConversionData) {
      window.memberEmailAddress = identity['email']
      dataLayerEvent.enhanced_conversion_data = {
        email: 'memberEmailAddress'
      }
    }
    windowGlobal.dataLayer.push(dataLayerEvent)
  }
}

function trackEventWithMatomo(event, parameters, identity, windowGlobal) {
  if (windowGlobal._paq !== undefined) {
    windowGlobal._paq.push(['trackEvent', parameters['category'], parameters['action'], parameters['name'], parameters['value']])
  }
}

function trackEventWithSegment(event, parameters, identity, windowGlobal) {
  if (windowGlobal.analytics !== undefined) {
    let segmentContext = identity['anonymousId'] ? {anonymousId: identity['anonymousId']} : {}

    if (windowGlobal.optimizely && windowGlobal.optimizely.get) {
      segmentContext['Optimizely'] = {userId: windowGlobal.optimizely.get('visitor').visitorId }
    }

    if (!isEmpty(identity)) {
      windowGlobal.analytics.identify(`csl-${ identity['id'] }`, {email: identity['email']}, segmentContext )
    }
    windowGlobal.analytics.track(event, parameters, segmentContext)
  }
}

const TRACKING_METHODS_BY_SLUG = {
  google_analytics: trackEventWithGoogleAnalytics,
  google_tag_manager: trackEventWithGtm,
  matomo: trackEventWithMatomo,
  segment: trackEventWithSegment
}

// we pass the global `window` in separately to assist us in stubbing easily with jest
export function trackEvent(event, parameters = {}, identity = {}, windowGlobal = window ) {
  for (let tracker of windowGlobal.Agra.Configuration.sendTrackingEventsTo) {
    TRACKING_METHODS_BY_SLUG[tracker](event, parameters, identity, windowGlobal)
  }

  return true
}

function paramsForGoogleAnalytics(params) {
  const { category: event_category, name: event_label, ...restParams } = params
  return {
    event_category,
    event_label,
    ...restParams
  }
}
