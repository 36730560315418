const I18nPlugin = {
  install(app, options) {
    const i18n = options.i18n

    app.config.globalProperties.i18n = i18n
    app.config.globalProperties.i18n.formattedCount = (i18nKey, count) => {
      return i18n.t(i18nKey, { count, formatted_count: i18n.toNumber(count, {precision: 0})})
    }
    app.config.globalProperties.translationsLoaded = true
    app.provide('i18n', i18n)
  }
}

export default I18nPlugin
