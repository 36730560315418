export const CHART_FORMAT = {
  pointHoverBackgroundColor: '#ffffff',
  pointHoverBorderColor: '#e2e2e2',
  pointHoverBorderWidth: 1,
  borderWidth: 2,
  pointRadius: 0,
  pointHoverRadius: 6,
  pointHitRadius: 10,
  fill: false,
  borderColor: [ '#0f91c2']
}

export const DATA_COLORS = [
  '#61a1e7',
  '#ff8f8f',
  '#7086f4',
  '#f0bb70',
  '#d27777',
  '#df71db',
  '#fdd455',
  '#eef270',
  '#b7e762',
  '#a465ed',
  '#7af5a3',
  '#79e7f4'
]

export const TYPE_TO_COLOR_MAP = {
  event: 'bg-primary',
  group: 'bg-secondary'
}

export const COOKIE_NAME_AGREED_COOKIES = 'agreed_cookies'

export const MIN_MD_SCREEN_WIDTH = 768
