import forEach from 'lodash/forEach' // this works on JS objects in addition to arrays
import isPlainObject from 'lodash/isPlainObject'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

// Warning: Only for serializable javascript objects without recursive references
// otherwise will have infinite recursion + stack overflow.

function makeObjectTransformer(transformWithFunction) {
  return function transformObjectKeys(sourceObject) {
    let transformedObject = {}
    if (Array.isArray(sourceObject)) {
      transformedObject = []
    }

    forEach(
      sourceObject,
      function(value, key) {
        if (isPlainObject(value) || Array.isArray(value)) {     // checks that a value is a plain object or an array - for recursive key conversion
          value = transformObjectKeys(value)                    // recursively update keys of any values that are also objects
        }
        transformedObject[transformWithFunction(key)] = value
      }
    )
    return transformedObject
  }
}

const objectKeysToCamelCase = makeObjectTransformer(camelCase)
const objectKeysToSnakeCase = makeObjectTransformer(snakeCase)

export {
  // For a plain JS object or array with snake_case keys,
  // recursively transform the keys to be camelCase instead.
  // Returns the transformed version.
  objectKeysToCamelCase,

  // For a plain JS object or array with camelCase keys,
  // recursively transform the keys to be snake_case instead.
  // Returns the transformed version.
  objectKeysToSnakeCase
}
