const TYPE_TO_COLOR_INDEX_MAP = {
  event: 0,
  group: 1
}

export default {
  install(app) {
    app.config.globalProperties.classFromColorIndex = function(colorIndex, primaryClass, secondaryClass) {
      if (colorIndex === 0) {
        return { [primaryClass]: true}
      }
      return { [secondaryClass]: true }
    }

    app.config.globalProperties.colorIndexForType = function(typeName) {
      return TYPE_TO_COLOR_INDEX_MAP[typeName]
    }
  }
}

