import ClassFromColorIndex from './helpers/class-from-color-index'
import AgraConfiguration from './agra-configuration'
import { ErrorLoggerVueInit } from '@/error-logger'

const applyCommonPluginsToVueApp = function(app) {
  app.use(ErrorLoggerVueInit)
  app.use(ClassFromColorIndex)
  app.use(AgraConfiguration)
}

export { applyCommonPluginsToVueApp }
