function urlWithParams(baseUrl, searchParamsMap) {
  const urlObj = new URL(baseUrl, window.location.origin)
  const urlSearchParams = urlObj.searchParams
  Object.entries(searchParamsMap).forEach(([k, v]) => {
    const stringValue = typeof v === 'string' ? v : JSON.stringify(v)
    urlSearchParams.append(k, stringValue)
  })

  // Deterministic ordering of url params. This is useful for when we check
  // the url with params in tests.
  urlSearchParams.sort()
  return urlObj.toString()
}

function urlParamsWithNamespace(paramsMap, namespace) {
  const newParamsMap = {}
  Object.entries(paramsMap).forEach(([k, v]) => {
    newParamsMap[`${namespace}[${k}]`] = v
  })
  return newParamsMap
}

export {
  urlParamsWithNamespace,
  urlWithParams
}
