import { importWithRetry } from '@/utils/component-loader'

export default class ErrorLogger {
  rollbarLogger = null
  appsignalLogger = null

  constructor() {
    const errorReportingConfig = window.Agra.Configuration.errorReporting
    if (errorReportingConfig.rollbar.enabled) {
      importWithRetry(() => import(/* webpackMode: "lazy"*/ 'rollbar'))
        .then(module => {
          this.rollbarLogger = new module.default(this.rollbarOptions(errorReportingConfig.rollbar))
        })
    } else if (errorReportingConfig.appsignal.enabled) {
      importWithRetry(() => import(/* webpackMode: "lazy"*/ '@appsignal/javascript'))
        .then(module => {
          this.appsignalLogger = new module.default({
            key: errorReportingConfig.appsignal.frontendKey
          })
        })
    }
  }

  rollbarOptions(config) {
    return {
      accessToken: config.publicAccessToken,
      autoInstrument: {
        contentSecurityPolicy: true,
        errorOnContentSecurityPolicy: true
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
      captureIp: false,
      enabled: true,
      environment: config.environment,
      ignoredMessages: ['ResizeObserver loop completed with undelivered notifications'],
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      },
      scrubTelemetryInputs: true
    }
  }

  error(message, exception) {
    if (!exception) {
      exception = new Error(message)
    }

    if (this.rollbarLogger) {
      this.rollbarLogger.error(message, exception)
    } else if (this.appsignalLogger) {
      this.appsignalLogger.sendError(exception)
    } else {
      // stub implementation for case when neither rollbar or appsignal are loaded. Just logs to the console.
      console.log(`[ERROR] ${message} - ${JSON.stringify(exception)}`)
    }
  }
}

export const ErrorLoggerVueInit = {
  install(app) {
    const errorLogger = new ErrorLogger()
    app.config.globalProperties.$errorLogger = errorLogger
  }
}
